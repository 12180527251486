/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

const getLocale = () => {
    let locale = '';
    if (typeof window !== 'undefined') {
        const userLocale = navigator.language.toLowerCase();
        const supportedLocales = ['en-us', 'en-ca', 'fr-ca', 'es-us'];
        locale = supportedLocales.includes(userLocale) ? userLocale : 'en-us';
    }
    return locale;
};

export default getLocale;
